<template>
	<div id="landEdit">
		<page-land-edit :is-shop="0"></page-land-edit>
	</div>
</template>

<script>
	import pageLandEdit from '@/components/layout/land/page-land-edit.vue'
	export default{
		components:{
			pageLandEdit
		},
		// beforeRouteLeave (to, from, next) {
		// 	to.meta.keepAlive = to.path == '/land/landList' ? true :false
		// 	next();
		// },
	}
</script>

<style>
	
</style>
